// Step 1: Import React
import { Link } from 'gatsby'
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { StaticImage } from "gatsby-plugin-image"

// Step 2: Define your component
const SocialPage = () => {
  return (
    <div className={container}>
      <main>
        <Layout>

          <StaticImage src="../../static/social.jpg" alt="Get Into Investing!" layout="constrained"/>
 
          <table align="center" width="90%">
            <tr>
              <td>
              <div><br />
                  <p>
                    <center>
                      <h2>Use the below links to follow along on social media and connect with like minded investors.</h2>
                    </center>
                  </p>
                  <br />

                  <p><h3><i><i>Get into Investing!</i> offical social media outlets:</i></h3>
                    <p><b><Link style={{ textDecoration: 'none' }} href="https://www.facebook.com/getintoinvesting">Facebook:</Link></b><br /> Follow the Facebook link for news and tips through the Facebook channels.<br /></p>
                    <p><b><Link style={{ textDecoration: 'none' }}href="https://www.twitter.com/getintoinvstng">Twitter:</Link></b><br /> Twitter is a good place to seek out new tips about news and tips from financial channels. Up to the second news and stock updates.<br /></p>
                    <p><b><Link style={{ textDecoration: 'none' }}href="https://www.youtube.com/channel/UCf07hLgScXX6UoT7QOCDKBg">YouTube:</Link></b><br /> A location for the views of our training and simple math in your financial instutute's website.<br /></p>
                  </p>
                  <br />

                  <p>
                    <h2><b>Mentions</b></h2>
                    <br />
                      <h3>During the editorial review of <i>Get Into Investing!</i> the editor shared these comments:</h3>
                      <li><b>Main Objective:</b> The main objective of <i>Get into Investing!</i> Is to provide a structured, detailed, educational guide for navigating the world of investing—including practical instructions on investing apps, successful strategies, and important terms. The book’s themes and objectives are clearly stated in the forward and constantly reinforced throughout the manuscript. In addition, the author has included useful “homework” assignments that enable the reader to ease into investing safely and easily. </li>
                      <br /><li><b>Structure:</b><i> Get into Investing!</i> is structured clearly and effectively, utilizing chapter sections and pictures to provide the manuscript with a clear sense of logic and purpose. Each chapter takes the reader further into the practice of investing, and the manuscript’s intelligent handling of progressively advanced information gives the reader a distinct sense of confidence. By the end, I felt like the manuscript had taught me a valuable skill set that could earn me tangible real-world benefits.</li>
                      <br /><li><b>Tone and Style:</b> The tone of the manuscript is also very effective. The author has employed an informal, down-to-earth approach to what can often feel like a complex and obtuse subject matter. This tone is perfect because it gives the reader the sense that he/she can successfully navigate this world of esoteric terms and math.</li>
                      <br /><li><b>Other Comments:</b> This is an extremely informative and engaging manuscript! The practical, real-world instructions are detailed and accessible, and I feel like they could provide beginning investors with some valuable tools. </li>
                      <br />
                      -Editor, Palmetto Publishing
                  </p>
                  <br />

                  </div>
              </td>  
            </tr>
          </table>

        </Layout>
      </main>
    </div>
  )
}

// Step 3: Export your component
export default SocialPage